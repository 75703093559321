import { BaseMapElement } from "../../base"
import { IPopulationMarker } from "@types"
import PopulationIcon from "./_population_icon.svg"
import { createCustomElement } from "../../../_utils"

export class PopulationMarker extends BaseMapElement<IPopulationMarker> {
  protected createElement(): HTMLDivElement {
    const element = createCustomElement("div", {
      width: "80px",
      height: "36px",
      padding: "5px",
      paddingLeft: "10px",
      position: "absolute",
      border: "1px solid white",
      borderRadius: "4px",
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
      gap: "6px",
      background: "#010101",
    }) as HTMLDivElement

    const iconContainer = createCustomElement("div", {
      width: "16px",
      height: "100%",
      display: "flex",
      justifyContent: "center",
    }) as HTMLDivElement
    iconContainer.innerHTML = PopulationIcon

    const statContainer = this.createStatContainer()

    element.appendChild(iconContainer)
    element.appendChild(statContainer)

    return element
  }

  private createStatContainer(): HTMLDivElement {
    const statContainer = createCustomElement("div", {
      height: "100%",
      display: "flex",
      flexDirection: "column",
      alignItems: "end",
      justifyContent: "space-between",
    }) as HTMLDivElement

    const popTitle = this.createPopTitle()
    const popIndex = this.createPopIndex()

    statContainer.appendChild(popTitle)
    statContainer.appendChild(popIndex)

    return statContainer
  }

  private createPopTitle(): HTMLParagraphElement {
    const popTitle = createCustomElement("p", {
      color: "white",
      fontSize: "10px",
      fontWeight: "700",
      margin: "0",
    }) as HTMLParagraphElement

    popTitle.innerHTML = this.dataEntity.population.toLocaleString()

    return popTitle
  }

  private createPopIndex(): HTMLDivElement {
    const popIndex = createCustomElement("div", {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      gap: "1px",
    }) as HTMLDivElement

    const bubbles: Record<string, HTMLDivElement> = {}

    for (let i = 0; i < 5; i++) {
      bubbles[i] = createCustomElement("div", {
        width: "8px",
        height: "8px",
        border: "1px solid white",
        borderRadius: "50%",
      }) as HTMLDivElement
    }

    getFilledIndices(this._dataEntity.population).forEach((i) => (bubbles[i].style.background = "#FF0000"))

    Object.values(bubbles).forEach((b) => popIndex.appendChild(b))

    return popIndex
  }
}

function getFilledIndices(population: number): number[] {
  const thresholds = [0, 300_000, 500_000, 800_000, 1_100_000]

  const res: number[] = []

  thresholds.forEach((t, i) => {
    if (population > t) res.push(i)
  })

  return res
}
