import { FilterController } from "components/map/fragments"
import { BaseMapElementFactory } from "../../base"
import { ELayers, ILayerMarker } from "@types"
import { TailingsMarker } from "./_TailingsMarker"

export class TailingsMarkerFactory extends BaseMapElementFactory<ILayerMarker, TailingsMarker> {
  constructor(dataEntityList: ILayerMarker[], private filterController: FilterController) {
    super(dataEntityList)
  }

  createElements = (parent: Element, preventCb: (element: Element) => void): void => {
    this.dataEntityList.forEach(this.createMarker)
    this.mountElements(parent, preventCb)
    this._filter()
  }

  private createMarker = (marker: ILayerMarker): void => {
    this.elements[marker.id] = new TailingsMarker(marker)
  }

  mountElements = (parent: Element, preventCb: (element: Element) => void): void => {
    this.dataEntityList.forEach((item) => {
      this.elements[item.id].mount(parent, preventCb)
    })
  }

  override calcPositions = (overlayProjection: google.maps.MapCanvasProjection): void => {
    this.dataEntityList.forEach((item) => {
      this.elements[item.id].calcPosition(overlayProjection)
    })
  }

  filter = (): void => this._filter()

  private _filter(): void {
    const isVisible = this.filterController.hasPassedFilters(ELayers.TAILINGS)
    this.setVisible(isVisible)
  }

  reset = (newData: ILayerMarker[]): void => {
    this.dispose()
    this.isVisible = true

    this.dataEntityList = newData
    this.elements = {}
  }
}
