import { BaseMapElement } from "../../base"
import { ILayerMarker } from "@types"
import TailingsIcon from "./_tailing_icon.svg"
import { createCustomElement } from "../../../_utils"

export class TailingsMarker extends BaseMapElement<ILayerMarker> {
  protected createElement(): HTMLDivElement {
    const element = createCustomElement("div", {
      width: "26px",
      height: "26px",
      position: "absolute",
    }) as HTMLDivElement

    element.innerHTML = TailingsIcon

    return element
  }

  override calcPosition = (overlayProjection: google.maps.MapCanvasProjection): void => {
    if (!this.dataEntity) return

    const pixelPos = overlayProjection.fromLatLngToDivPixel(this.dataEntity.coord)!

    this.element.style.left = Math.round(pixelPos.x - parseInt(this.element.style.width, 10) / 2) + "px"
    this.element.style.top = Math.round(pixelPos.y - parseInt(this.element.style.height, 10)) + "px"
  }
}
